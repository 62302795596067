

































import { Component, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

@Component({
  beforeRouteEnter(to: Route, from: Route, next: (vm?: any) => void) {
    next((vm: any) => {
      let path = to.path;
      if (path[0] === "/") {
        path = path.replace(/^\/|\/$/g, "");
      }
      path = path.replaceAll("settings/", "");
      if (vm.currentIndex !== path) {
        vm.currentIndex = path;
      }
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: (vm?: any) => void) {
    let path = to.path;
    if (path[0] === "/") {
      path = path.replace(/^\/|\/$/g, "");
    }
    path = path.replaceAll("settings/", "");
    const vm = this as any;
    if (vm.currentIndex !== path) {
      vm.currentIndex = path;
    }
    next();
  }
})
export default class SettingIndex extends Vue {
  currentIndex = "managers";
}
